import _ from "lodash";
import { getBasePath } from "./config";

export enum Platform {
  ArtBlocks = "ART_BLOCKS",
  ArtBlocksCurated = "ART_BLOCKS_CURATED",
  ArtBlocksPresents = "ART_BLOCKS_PRESENTS",
  ArtBlocksCollaborations = "ART_BLOCKS_COLLABORATIONS",
  ArtBlocksPlayground = "ART_BLOCKS_PLAYGROUND",
  ArtBlocksFactory = "ART_BLOCKS_FACTORY",
  ArtBlocksExplorations = "ART_BLOCKS_EXPLORATIONS",
  ArtBlocksStudio = "ART_BLOCKS_STUDIO",
  GrailersDao = "GRAILERS_DAO",
  BrightMoments = "BRIGHT_MOMENTS",
  Plottables = "PLOTTABLES",
  Proof = "PROOF",
  VCA = "VERTICAL_CRYPTO_ART",
  Hodlers = "HODLERS",
  Trameparis = "TRAMEPARIS",
  TrameCPG = "TRAMECPG",
  Artwrld = "ARTWRLD",
  ATP = "ATP",
  AOI = "AOI",
  Endaoment = "ENDAOMENT",
  CoinDesk = "COINDESK",
  NineDccxyz = "9DCCXYZ",
  RedLion = "RED_LION",
  Axiom = "AXIOM",
  Elsewhere = "ELSEWHERE",
  FlamingoDao = "FLAMINGO_DAO",
  FabDao = "FAB_DAO",
  GazelliArtHouse = "GAZELLI_ART_HOUSE",
  LegendsOfMetaterra = "LEGENDS_OF_METATERRA",
  LightHouseCards = "LIGHT_HOUSE_CARDS",
  Shiseido = "SHISEIDO",
  Sothebys = "SOTHEBYS",
  SquiggleDao = "SQUIGGLE_DAO",
  TBOAClub = "TBOA_CLUB",
  Tender = "TENDER",
  UnitLondon = "UNIT_LONDON",
  VertuFineArt = "VERTU_FINE_ART",
  DisruptiveGallery = "DISRUPTIVE_GALLERY",
  GlitchGallery = "GLITCH_GALLERY",
  NobleGallery = "NOBLE_GALLERY",
  HouseOfQoa = "HOUSE_OF_QOA",
}

export enum PlatformFormat {
  ArtBlocks = "ART_BLOCKS",
  ProofGrails = "PROOF_GRAILS",
  BrainDrops = "BRAIN_DROPS",
  Generic = "GENERIC",
  GenericSplittable = "GENERIC_SPLITTABLE", // contract can be split into multiple collections based on "collection_name" in metadata
}

export const PARTNER_TYPE = {
  ART_BLOCKS_ENGINE: "ART_BLOCKS_ENGINE",
  ART_BLOCKS_FLAGSHIP: "ART_BLOCKS_FLAGSHIP",
  NONE: "NONE",
};

export type PartnerType = keyof typeof PARTNER_TYPE;

export type PlatformData = {
  kind: Platform;
  slug: string;
  name: string;
  description?: string;
  descriptionShort?: string;
  logoImageUrl: string;
  platformColor?: string;
  coverImageUrl?: string;
  websiteUrl?: string;
  websiteLinkImageUrl?: string;
  twitterUsername?: string;
  discordLink?: string;
  instagramUsername?: string;
  featured?: boolean; // whether or not we show the platform in the platforms list on explore page
  partnerType?: PartnerType;
};

const PLATFORM_DATA: PlatformData[] = [
  {
    kind: Platform.ArtBlocks,
    name: "Art Blocks",
    logoImageUrl: `${getBasePath()}/logos/artblocks-curated-old.svg`,
    description:
      "Elevating the medium: Art Blocks is a premier publisher of generative art.",
    websiteUrl: "https://artblocks.io/curated",
    twitterUsername: "artblocks_io",
    discordLink: "https://discord.com/invite/artblocks",
    instagramUsername: "artblocks_io",
    slug: "art-blocks",
    platformColor: "#000000",
    featured: false,
    partnerType: "ART_BLOCKS_FLAGSHIP",
  },
  {
    kind: Platform.ArtBlocksStudio,
    name: "Art Blocks Studio",
    logoImageUrl: `${getBasePath()}/logos/artblocks-studio.svg`,
    description:
      "Art Blocks Studio is a self-publishing platform designed to empower preeminent generative artists by facilitating the direct release of their collections. The Studio allows artists to manage their own releases and provides them with autonomy in both creation and distribution.",
    descriptionShort: "Empowering independent creators",
    websiteUrl: "https://artblocks.io/marketplace/category/art-blocks-studio",
    twitterUsername: "artblocks_io",
    discordLink: "https://discord.com/invite/artblocks",
    instagramUsername: "artblocks_io",
    slug: "art-blocks-studio",
    platformColor: "#D9E4E6",
    coverImageUrl: `${getBasePath()}/platforms/artblocks-studio-cover.svg`,
    featured: true,
    partnerType: "ART_BLOCKS_ENGINE",
  },
  {
    kind: Platform.ArtBlocksCurated,
    name: "Art Blocks Curated",
    logoImageUrl: `${getBasePath()}/logos/artblocks-curated.svg`,
    description:
      "The Curated collection is the foundational series of Art Blocks. It represents our vision for the pinnacle of generative art, showcasing projects that embody world-class innovation, technical rigor, and aesthetic beauty. Originally organized into Series 1 through 8 until October 2022, the collection continues without series designations and advances our mission to push the boundaries of the generative art medium.",
    descriptionShort: "Elevating the medium of generative art",
    websiteUrl: "https://artblocks.io/curated",
    websiteLinkImageUrl: `${getBasePath()}/logos/curated-website-link.svg`,
    twitterUsername: "artblocks_io",
    discordLink: "https://discord.com/invite/artblocks",
    instagramUsername: "artblocks_io",
    slug: "art-blocks-curated",
    platformColor: "#D5D5D5",
    coverImageUrl: `${getBasePath()}/platforms/artblocks-curated-cover.svg`,
    featured: true,
    partnerType: "ART_BLOCKS_FLAGSHIP",
  },
  {
    kind: Platform.ArtBlocksPresents,
    name: "Art Blocks Presents",
    logoImageUrl: `${getBasePath()}/logos/artblocks-presents.svg`,
    description:
      "Established in November 2022 as a successor to the Playground and Factory collections, Art Blocks Presents featured generative art projects that were selected by the Art Blocks team and complemented the Curated collection. The Presents collection embodied our ongoing standards for artistic creativity, conceptual rigor, and technical achievement.",
    descriptionShort: "Notable projects hand-selected by the Art Blocks team",
    websiteUrl: "https://artblocks.io/marketplace/category/art-blocks-presents",
    twitterUsername: "artblocks_io",
    discordLink: "https://discord.com/invite/artblocks",
    instagramUsername: "artblocks_io",
    slug: "art-blocks-presents",
    platformColor: "#E6E3D9",
    coverImageUrl: `${getBasePath()}/platforms/artblocks-presents-cover.svg`,
    featured: true,
    partnerType: "ART_BLOCKS_FLAGSHIP",
  },
  {
    kind: Platform.ArtBlocksCollaborations,
    name: "Art Blocks Collaborations",
    logoImageUrl: `${getBasePath()}/logos/artblocks-collaborations.svg`,
    description:
      "Launched in June 2022 with our debut partner Pace Verso, the Collaborations designation includes co-branded releases that combine the mission of Art Blocks with the unique visions of select partners. These collections represent true collaborations between Art Blocks and our partners to shape the program offerings and extend the global reach of generative art.",
    descriptionShort:
      "Unique projects released in conjunction with our collaborative partners",
    websiteUrl:
      "https://artblocks.io/marketplace/category/art-blocks-collaborations",
    twitterUsername: "artblocks_io",
    discordLink: "https://discord.com/invite/artblocks",
    instagramUsername: "artblocks_io",
    slug: "art-blocks-collaborations",
    platformColor: "#E6DFD9",
    coverImageUrl: `${getBasePath()}/platforms/artblocks-collaborations-cover.svg`,
    featured: true,
    partnerType: "ART_BLOCKS_FLAGSHIP",
  },
  {
    kind: Platform.ArtBlocksPlayground,
    name: "Art Blocks Playground",
    logoImageUrl: `${getBasePath()}/logos/artblocks-playground.svg`,
    description:
      "Established in February 2021, Art Blocks Playground provided a space for previously Curated artists to experiment and innovate further in generative art. Alongside the Factory designation, Playground was closed in October 2022 with the debut of Art Blocks Presents.",
    descriptionShort: "Innovative releases from previously curated artists",
    websiteUrl:
      "https://artblocks.io/marketplace/category/art-blocks-playground",
    twitterUsername: "artblocks_io",
    discordLink: "https://discord.com/invite/artblocks",
    instagramUsername: "artblocks_io",
    slug: "art-blocks-playground",
    platformColor: "#E7D6D9",
    coverImageUrl: `${getBasePath()}/platforms/artblocks-playground-cover.svg`,
    featured: true,
    partnerType: "ART_BLOCKS_FLAGSHIP",
  },
  {
    kind: Platform.ArtBlocksFactory,
    name: "Art Blocks Factory",
    logoImageUrl: `${getBasePath()}/logos/artblocks-factory.svg`,
    description:
      "Launched in March 2021, Art Blocks Factory provided an editorially open space for artists to deploy generative art projects. Together with Art Blocks Playground, the Factory was closed in October 2022 with the debut of Art Blocks Presents.",
    descriptionShort:
      "Generative projects spanning a variety of creative styles",
    websiteUrl: "https://artblocks.io/marketplace/category/art-blocks-factory",
    twitterUsername: "artblocks_io",
    discordLink: "https://discord.com/invite/artblocks",
    instagramUsername: "artblocks_io",
    slug: "art-blocks-factory",
    platformColor: "#E5D6E7",
    coverImageUrl: `${getBasePath()}/platforms/artblocks-factory-cover.svg`,
    featured: true,
    partnerType: "ART_BLOCKS_FLAGSHIP",
  },
  {
    kind: Platform.ArtBlocksExplorations,
    name: "Art Blocks Explorations",
    logoImageUrl: `${getBasePath()}/logos/artblocks-explorations.svg`,
    description:
      "Launched with our Friendship Bracelets project in advance of Marfa Weekend in November 2022, the Explorations collection establishes a space for experimental, community-focused releases. Consisting of commissioned projects that embody the spirit of innovation and experimentation within the Art Blocks ecosystem, this collection embraces new approaches that are conceptualized and realized in collaboration with the Art Blocks team.",
    descriptionShort: "Experimental commissions that break the mold",
    websiteUrl:
      "https://artblocks.io/marketplace/category/art-blocks-explorations",
    twitterUsername: "artblocks_io",
    discordLink: "https://discord.com/invite/artblocks",
    instagramUsername: "artblocks_io",
    slug: "art-blocks-explorations",
    platformColor: "#D5D7E7",
    coverImageUrl: `${getBasePath()}/platforms/artblocks-explorations-cover.svg`,
    featured: true,
    partnerType: "ART_BLOCKS_FLAGSHIP",
  },
  {
    kind: Platform.BrightMoments,
    name: "Bright Moments",
    description:
      "Bright Moments is a digital art gallery that started in Venice Beach and has gone on to create art communities around the world through the creation of unique, in-person experiences & CryptoCitizens. We work with generative and AI artists to help them produce iconic real world experiences of their art and have established communities in LA, New York, Berlin, London, Mexico City, Tokyo, Buenos Aires, Paris, and Venice.",
    slug: "bright-moments",
    instagramUsername: "brightmomentsgallery",
    discordLink: "https://discord.com/invite/bright-moments",
    websiteUrl: "https://www.brightmoments.io/",
    twitterUsername: "brtmoments",
    logoImageUrl: `${getBasePath()}/logos/bright-moments.png`,
    featured: true,
    partnerType: "ART_BLOCKS_ENGINE",
  },
  {
    kind: Platform.Proof,
    name: "PROOF",
    slug: "proof",
    description:
      "At PROOF, we're focused on creating unique experiences for collectors to connect with artists and to own and champion their art. Art is more than a floor price—it's humanity's handprint on the world.",
    websiteUrl: "https://www.proof.xyz/",
    twitterUsername: "proof_xyz",
    logoImageUrl: `${getBasePath()}/logos/proof.jpeg`,
    platformColor: "#1b1d2c",
    featured: true,
    partnerType: "ART_BLOCKS_ENGINE",
  },
  {
    kind: Platform.VCA,
    name: "Vertical Crypto Art",
    slug: "vca",
    description:
      "the Vault of Contemporary Art. This new type of gallery and digital architecture is here to complement the wondrous experience of viewing works of art in person. It's about creating a wholly new way of enjoying exhibitions, from anywhere in the world, at any time.",
    websiteUrl: "https://vca.gallery/",
    logoImageUrl: `${getBasePath()}/logos/vca.jpeg`,
    featured: true,
    partnerType: "ART_BLOCKS_ENGINE",
  },
  {
    kind: Platform.GrailersDao,
    name: "Grailers",
    description:
      "GrailersDAO aims to collect NFTs that are the pinnacle of generative art. We call them grails.",
    websiteUrl: "https://www.grailers.com/",
    discordLink: "https://discord.com/invite/grailers",
    slug: "grailers-dao",
    twitterUsername: "grailersdao",
    logoImageUrl: `${getBasePath()}/logos/grailers.png`,
    featured: true,
    partnerType: "ART_BLOCKS_ENGINE",
  },
  {
    kind: Platform.Artwrld,
    name: "Artwrld",
    logoImageUrl: `${getBasePath()}/logos/artwrld.jpeg`,
    slug: "artwrld",
    twitterUsername: "artw__rld",
    instagramUsername: "artw__rld",
    discordLink: "https://discord.com/invite/JFWk54Pfdw",
    description:
      "Artwrld works collaboratively with artists to commission and produce projects that engage broad audiences and explore the emerging possibilities of blockchain technology for creative practice. We support artists throughout the entire process of making new work—from the initial concept to the final presentation—while fostering critical conversations about the issues driving digital art and culture.",
    featured: true,
    partnerType: "ART_BLOCKS_ENGINE",
  },
  {
    kind: Platform.Hodlers,
    name: "Hodlers",
    slug: "hodlers-one",
    logoImageUrl: `${getBasePath()}/logos/hodlers.png`,
    twitterUsername: "hodlersone",
    websiteUrl: "https://www.hodlers.one/",
    description:
      "We are a pioneering contemporary art platform that brings together a vibrant community of art enthusiasts, curators, and artists from around the world. Driven by the philosophy 'In Art We HODL', our mission is to empower everyone to explore, collect, learn about, and engage with crypto art like never before.",
    featured: true,
    partnerType: "ART_BLOCKS_ENGINE",
  },
  {
    kind: Platform.Plottables,
    name: "Plottables",
    slug: "plottables",
    description:
      "A curated on-chain generative art platform of pen plottable nfts",
    websiteUrl: "https://plottables.io/",
    twitterUsername: "plottablesio",
    instagramUsername: "plottables.io",
    discordLink: "https://discord.gg/umEbqdFxQv",
    logoImageUrl: `${getBasePath()}/logos/plottables.jpg`,
    featured: true,
    partnerType: "ART_BLOCKS_ENGINE",
  },
  {
    kind: Platform.Trameparis,
    name: "TRAME",
    slug: "trameparis",
    description:
      "TRAME is a dynamic and pioneering studio and gallery at the intersection of art, design, technology, and craft. ",
    websiteUrl: "https://trameparis.com/",
    twitterUsername: "trameparis",
    instagramUsername: "trameparis",
    logoImageUrl: `${getBasePath()}/logos/trameparis.jpg`,
    featured: true,
    partnerType: "ART_BLOCKS_ENGINE",
  },
  {
    kind: Platform.TrameCPG,
    name: "CPG",
    slug: "cpg",
    description: "Creative product studio",
    websiteUrl: "https://www.clubcpg.com/",
    twitterUsername: "CPGCLUB",
    logoImageUrl: `${getBasePath()}/logos/trame-cpg.png`,
    featured: true,
    partnerType: "ART_BLOCKS_ENGINE",
  },
  {
    kind: Platform.ATP,
    name: "ATP",
    slug: "atp",
    websiteUrl: "https://www.art.tennis/",
    twitterUsername: "atptour",
    logoImageUrl: `${getBasePath()}/logos/atp.jpg`,
    featured: true,
    partnerType: "ART_BLOCKS_ENGINE",
  },
  {
    kind: Platform.AOI,
    name: "AOI",
    slug: "aoi",
    description:
      "AOI is the movement for emerging art and technology. Discover the artists of the future.",
    websiteUrl: "https://aoi.com/",
    twitterUsername: "ArtOnInternet",
    instagramUsername: "artoninternet",
    logoImageUrl: `${getBasePath()}/logos/aoi.jpg`,
    featured: true,
    partnerType: "ART_BLOCKS_ENGINE",
  },
  {
    kind: Platform.Endaoment,
    name: "Endaoment",
    slug: "endaoment",
    websiteUrl: "https://www.thecolorsthatheal.com/",
    twitterUsername: "endaoment",
    logoImageUrl: `${getBasePath()}/logos/endaoment.jpg`,
    featured: true,
    partnerType: "ART_BLOCKS_ENGINE",
  },
  {
    kind: Platform.CoinDesk,
    name: "CoinDesk",
    slug: "coindesk",
    websiteUrl: "https://www.coindesk.com/",
    twitterUsername: "CoinDesk",
    logoImageUrl: `${getBasePath()}/logos/coindesk.png`,
    featured: true,
    partnerType: "ART_BLOCKS_ENGINE",
  },
  {
    kind: Platform.NineDccxyz,
    name: "9dcc",
    slug: "9dcc",
    websiteUrl: "https://www.9dcc.xyz/",
    twitterUsername: "9dccxyz",
    instagramUsername: "9dccxyz",
    logoImageUrl: `${getBasePath()}/logos/9dccxyz.jpg`,
    featured: true,
    partnerType: "ART_BLOCKS_ENGINE",
  },
  {
    kind: Platform.RedLion,
    name: "Redlion",
    slug: "redlion",
    websiteUrl: "https://www.redlion.red/",
    twitterUsername: "redlion",
    logoImageUrl: `${getBasePath()}/logos/redlion.jpg`,
    featured: true,
    partnerType: "ART_BLOCKS_ENGINE",
  },
  {
    kind: Platform.Axiom,
    name: "Axiom",
    slug: "axiom",
    websiteUrl: "https://nft.axiomspace.com/",
    twitterUsername: "Axiom_Space",
    logoImageUrl: `${getBasePath()}/logos/axiom.jpg`,
    featured: true,
    partnerType: "ART_BLOCKS_ENGINE",
  },
  {
    kind: Platform.Elsewhere,
    name: "Elsewhere",
    slug: "elsewhere",
    twitterUsername: "ElsewhereArt_",
    instagramUsername: "elsewhereart_",
    websiteUrl: "https://elsewhere.art/",
    logoImageUrl: `${getBasePath()}/logos/elsewhere.jpg`,
    featured: true,
    partnerType: "ART_BLOCKS_ENGINE",
  },
  {
    kind: Platform.FlamingoDao,
    name: "FlamingoDAO",
    slug: "flamingodao",
    twitterUsername: "FLAMINGODAO",
    websiteUrl: "https://flamingodao.xyz/",
    logoImageUrl: `${getBasePath()}/logos/flamingodao.jpg`,
    featured: true,
    partnerType: "ART_BLOCKS_ENGINE",
  },
  {
    kind: Platform.FabDao,
    name: "Foundation for Art and Blockchain",
    slug: "fabdao",
    twitterUsername: "foundation_artb",
    websiteUrl: "https://fabdao.art/",
    logoImageUrl: `${getBasePath()}/logos/fabdao.jpg`,
    featured: true,
    partnerType: "ART_BLOCKS_ENGINE",
  },
  {
    kind: Platform.GazelliArtHouse,
    name: "Gazelli Art House",
    slug: "gazelli",
    twitterUsername: "GazelliArtHouse",
    websiteUrl: "https://nft.gazell.io/",
    logoImageUrl: `${getBasePath()}/logos/gazelli.jpg`,
    featured: true,
    partnerType: "ART_BLOCKS_ENGINE",
  },
  {
    kind: Platform.LegendsOfMetaterra,
    name: "Legends of Metaterra",
    slug: "legendsofmetaterra",
    twitterUsername: "metaterragame",
    websiteUrl: "https://legendsofmetaterra.com/",
    logoImageUrl: `${getBasePath()}/logos/legendsofmetaterra.jpg`,
    featured: true,
    partnerType: "ART_BLOCKS_ENGINE",
  },
  {
    kind: Platform.LightHouseCards,
    name: "Light House Cards",
    description: "Ownable Digital Cards",
    slug: "light-house-cards",
    twitterUsername: "light_house_io",
    websiteUrl: "https://lighthousecards.io/",
    logoImageUrl: `${getBasePath()}/logos/light-house-cards.jpg`,
    featured: true,
    partnerType: "ART_BLOCKS_ENGINE",
  },
  {
    kind: Platform.Shiseido,
    name: "Shiseido",
    description: "Sharing art, science and beauty with the world since 1872.",
    slug: "shiseido",
    twitterUsername: "shiseido_usa",
    instagramUsername: "shiseido",
    websiteUrl: "https://www.shiseido.com/us/en/futurereflections/",
    logoImageUrl: `${getBasePath()}/logos/shiseido.jpg`,
    featured: true,
    partnerType: "ART_BLOCKS_ENGINE",
  },
  {
    kind: Platform.Sothebys,
    name: "Sotheby's",
    description:
      "Sotheby's Metaverse. Auction house for a selection of rare NFTs and digital art.",
    slug: "sothebys",
    twitterUsername: "Sothebysverse",
    websiteUrl: "https://metaverse.sothebys.com/",
    logoImageUrl: `${getBasePath()}/logos/sothebys.jpg`,
    featured: true,
    partnerType: "ART_BLOCKS_ENGINE",
  },
  {
    kind: Platform.SquiggleDao,
    name: "SquiggleDAO",
    description:
      "SquiggleDAO is elevating the Chromie Squiggle to its rightful place in culture, as a beloved and seminal art project.",
    slug: "squiggledao",
    twitterUsername: "squiggleDAO",
    websiteUrl: "https://www.squiggledao.com/",
    logoImageUrl: `${getBasePath()}/logos/squiggledao.jpg`,
    featured: true,
    partnerType: "ART_BLOCKS_ENGINE",
  },
  {
    kind: Platform.TBOAClub,
    name: "TBOA Club",
    slug: "tboa",
    twitterUsername: "TBOAClub",
    websiteUrl: "https://www.tboa.club/",
    logoImageUrl: `${getBasePath()}/logos/tboa.jpg`,
    featured: true,
    partnerType: "ART_BLOCKS_ENGINE",
  },
  {
    kind: Platform.Tender,
    name: "Tender",
    slug: "tender",
    description:
      "Stewards of generative art. Cross-platform curation, collector tools, collaborative releases, Pass-holder community.",
    twitterUsername: "tender_art",
    websiteUrl: "https://tender.art/",
    logoImageUrl: `${getBasePath()}/logos/tender.jpg`,
    featured: true,
    partnerType: "ART_BLOCKS_ENGINE",
  },
  {
    kind: Platform.UnitLondon,
    name: "Unit London",
    slug: "unitlondon",
    description:
      "A new frontier for contemporary art. Celebrating and supporting artists using ascendant technologies to expand the art audience",
    twitterUsername: "weareunitlondon",
    instagramUsername: "unitlondon",
    websiteUrl: "https://unitlondon.com/",
    logoImageUrl: `${getBasePath()}/logos/unitlondon.jpg`,
    featured: true,
    partnerType: "ART_BLOCKS_ENGINE",
  },
  {
    kind: Platform.VertuFineArt,
    name: "Vertu Fine Art",
    description:
      "Our gallery's focus is on the luminaries of Pop, Abstract Expressionism, & Generative Art. Specializing in editions and multiples.",
    slug: "vertu-fine-art",
    twitterUsername: "VertuFineArt",
    instagramUsername: "vertufineart",
    websiteUrl: "https://www.vertufineart.com/",
    logoImageUrl: `${getBasePath()}/logos/vertufineart.png`,
    featured: true,
    partnerType: "ART_BLOCKS_ENGINE",
  },
  {
    kind: Platform.DisruptiveGallery,
    name: "The Disruptive Gallery",
    description:
      "Multidisciplinary gallery focused on generative, video, kinetic, and light-based artworks in London's Notting Hill",
    slug: "disruptive-gallery",
    twitterUsername: "disruptive_art_",
    websiteUrl: "https://tdg.art/",
    logoImageUrl: `${getBasePath()}/logos/disruptive-gallery.jpg`,
    featured: true,
    partnerType: "ART_BLOCKS_ENGINE",
  },
  {
    kind: Platform.GlitchGallery,
    name: "Glitch Gallery",
    description: "An analog gallery for digital objects",
    slug: "glitch-gallery",
    twitterUsername: "glitchmarfa",
    instagramUsername: "glitchmarfa",
    websiteUrl: "https://www.glitchmarfa.com/",
    logoImageUrl: `${getBasePath()}/logos/glitch-gallery.png`,
    platformColor: "#E65245",
    featured: true,
    partnerType: "ART_BLOCKS_ENGINE",
  },
  {
    kind: Platform.NobleGallery,
    name: "Noble Gallery",
    description:
      "Contemporary art + personal growth. Showcasing emerging, established and critically acclaimed artists. Founded in 2022",
    slug: "noble-gallery",
    twitterUsername: "noble_gallery",
    discordLink: "https://discord.gg/NHtWbQB97A",
    websiteUrl: "https://noblegallery.com/",
    logoImageUrl: `${getBasePath()}/logos/noble-gallery.jpg`,
    featured: true,
    partnerType: "ART_BLOCKS_ENGINE",
  },
  {
    kind: Platform.HouseOfQoa,
    name: "House of QOA",
    description:
      "Fashion that makes you authentic 👑 🟣 🦍 • Fashion x Tech x Art x Community",
    slug: "house-of-qoa",
    twitterUsername: "houseofqoa",
    websiteUrl: "https://houseofqoa.com/",
    logoImageUrl: `${getBasePath()}/logos/houseofqoa.jpg`,
    featured: true,
    partnerType: "ART_BLOCKS_ENGINE",
  },
];

const PLATFORM_DATA_BY_KIND = _.keyBy(PLATFORM_DATA, "kind");
const PLATFORM_DATA_BY_SLUG = _.keyBy(PLATFORM_DATA, "slug");

export const getPlatformDataByKind = (platformKind: Platform) => {
  return PLATFORM_DATA_BY_KIND[platformKind];
};

export const getPlatformDataBySlug = (platformSlug: string) => {
  return PLATFORM_DATA_BY_SLUG[platformSlug];
};

export const getPlatformData = (platformKindOrSlug: string) => {
  return (
    getPlatformDataByKind(platformKindOrSlug as any) ||
    getPlatformDataBySlug(platformKindOrSlug)
  );
};

export const listPlatforms = () => {
  return PLATFORM_DATA;
};

export const listFeaturedPlatforms = () => {
  return listPlatforms().filter((platform) => platform.featured);
};

export const listFeaturedNonArtBlocksPlatforms = () => {
  return listFeaturedPlatforms().filter(
    (platform) => !isArtBlocksPlatform(platform)
  );
};

export const listFeaturedArtBlocksPlatforms = () => {
  return listFeaturedPlatforms().filter((platform) =>
    isArtBlocksPlatform(platform)
  );
};

const ART_BLOCKS_PLATFORM_KINDS = [
  Platform.ArtBlocksCurated,
  Platform.ArtBlocksCollaborations,
  Platform.ArtBlocksExplorations,
  Platform.ArtBlocksFactory,
  Platform.ArtBlocksPlayground,
  Platform.ArtBlocksPresents,
  Platform.ArtBlocksStudio,
] as const;

export type ArtBlocksPlatformKind = (typeof ART_BLOCKS_PLATFORM_KINDS)[number];

export function isArtBlocksPlatform(
  platform?: PlatformData
): platform is PlatformData & { kind: ArtBlocksPlatformKind } {
  if (!platform || !platform.kind) return false;
  return (ART_BLOCKS_PLATFORM_KINDS as Readonly<Array<Platform>>).includes(
    platform.kind
  );
}
